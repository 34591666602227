import PropTypes from "prop-types";
import * as XLSX from "xlsx";
import ModalGeneric from "./ModalGeneric";
import { useState } from "react";

const Excel = ({ element, filename, sheets, count, total }) => {
  const [openModal, setOpenModal] = useState(false);

  const checkIfModalShouldBeDisplay = () => {
    if (!total || !count) {
      return true;
    }

    return count < total;
  };

  const onExport = () => {
    console.log(count, total);
    if (checkIfModalShouldBeDisplay()) {
      setOpenModal(true);
    } else {
      handleExport();
    }
  };

  const handleExport = () => {
    if (!sheets || sheets.length === 0) {
      return;
    }

    var workbook = XLSX.utils.book_new();

    for (let index = 0; index < sheets.length; index++) {
      const sheet = sheets[index];
      var worksheet = XLSX.utils.json_to_sheet(sheet.data);
      XLSX.utils.book_append_sheet(workbook, worksheet, sheet.name);
    }

    XLSX.writeFile(workbook, filename);

    setOpenModal(false);
  };

  if (element) {
    return (
      <>
        {openModal && (
          <ModalGeneric
            id="someElementsHiddenModal"
            modalTitle="Ufullstendig rapport"
            open={openModal}
            openModal={() => setOpenModal(true)}
            closeModal={() => setOpenModal(false)}
            submit={handleExport}
            closeButtonText="Avbryt"
            submitButtonText="Fortsett allikevel"
          >
            <p>
              Noen rader mangler! Trykk "Avbryt", gå nederst i tabellen og utvid
              "Rader per side" til "Alle" før du prøver igjen.
            </p>
          </ModalGeneric>
        )}
        {element(onExport)}
      </>
    );
  }

  return null;
};

Excel.propTypes = {
  tableTitle: PropTypes.string,
  filename: PropTypes.string,
  element: PropTypes.func,
  sheet: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      data: PropTypes.arrayOf(PropTypes.object),
    })
  ),
  total: PropTypes.number,
  count: PropTypes.number,
};

export default Excel;
